#root {
  height: 100vh;
}

.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 90vh;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.App-header {
  align-items: center;
  background-color: #4c7676;
  color: #f1e3e3;
  display: flex;
  flex-direction: column;
  font-size: 30px;
  justify-content: center;
}

.divider {
  background-color: #f1e3e3;
  height: 2px;
  max-width: 700px;
  width: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  animation: line-grow 1.5s ease-in-out forwards;

  &#top {
    top: 20px;
  }

  &#bottom {
    bottom: 20px;
  }
}

@keyframes line-grow {
  0% {
    width: 0;
  }
  100% {
    width: 85vw;
  }
}
