.question-container {
  background-color: #f1e3e3;
  border-radius: 6px;
  color: #726b6b;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  gap: 20px;
  margin-bottom: 20px;
  max-width: 400px;
  padding: 20px;
}

.question-container > div {
  font-size: 25px;
}

.choice-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

%choice-button-shared {
  border-radius: 6px;
  font-size: 24px;
  font-weight: 600;
  min-height: 50px;
  margin: 8px;
  min-width: 200px;
}

.choice-button,
.next-button {
  @extend %choice-button-shared;

  &:disabled {
    background-color: #726b6b;
    border: #f1e3e3 1px solid;
    color: #4f4a4a;
  }

  &:enabled {
    background-color: transparent;
    border: #f1e3e3 3px solid;
    color: #f1e3e3;
    cursor: pointer;
  }

  &:enabled:hover {
    background-color: #f1e3e3;
    // TODO: figure out if this is needed
    // border: none;
    color: #726b6b;
  }
}

.choice-button--correct {
  @extend %choice-button-shared;
  border: #62d3ab solid 3px;
}

.choice-button--incorrect {
  @extend %choice-button-shared;
  border: #934f4f solid 3px;
}
