.drop-down {
  position: relative;
  width: 100%;

  &-button,
  &-open-button {
    background-color: #f1e3e3;
    border: none;
    color: #726b6b;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    height: auto;
    padding: 20px;
    text-align: left;
    width: 100%;
  }

  &-button:disabled,
  &-open-button:disabled {
    background-color: rgba(239, 239, 239, 0.3);
    cursor: unset;
  }

  &-open-button {
    border-radius: 6px;
  }

  &-inner-button {
    display: grid;
    grid-template-columns: auto auto;

    :last-child {
      text-align: right;
    }
  }

  &-inner-text-unselected {
    opacity: 0.5;
  }

  &-list-container {
    max-height: 400px;
    position: absolute;
    width: 100%;
    z-index: 1;
    margin-top: 6px;
  }

  &-list {
    border-radius: 6px;
    max-height: 400px;
    overflow: auto;
  }

  &-icon {
    &-container {
      display: flex;
      flex-direction: row-reverse;
    }

    &-open {
      transform: rotate(180deg);
      transition-duration: 0.25s;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
      -webkit-transition-duration: 0.25s;
      -moz-transition-duration: 0.25s;
      -o-transition-duration: 0.25s;
      transition-duration: 0.25s;
    }
  }
}
