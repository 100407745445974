.start-button {
  margin-top: 30px;
  width: 200px;
  height: 50px;
  border-radius: 6px;
  font-size: 24px;
  font-weight: 600;

  &:disabled {
    border: #f1e3e3 1px solid;
    background-color: #726b6b;
    color: #4f4a4a;
  }

  &:enabled {
    cursor: pointer;
    border: #f1e3e3 3px solid;
    background-color: transparent;
    color: #f1e3e3;
  }

  &:enabled:hover {
    border: none;
    background-color: #f1e3e3;
    color: #726b6b;
  }
}
