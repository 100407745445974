.result-area {
  background-color: #f1e3e3;
  border-radius: 6px;
  color: #726b6b;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  gap: 20px;
  margin-bottom: 20px;
  max-width: 80vw;
  width: 600px;
  padding: 20px;
}
